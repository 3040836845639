import React from "react";
import { useParams } from "react-router-dom";
import widgets from "./widgets";
import { useTaskForm } from "./useTaskForm";
import taskTypes, { TaskFormTypeNamesType, TaskFormTypes } from "./types";
import { useOnline } from "../../core/hooks/useOnline";
import { Trans } from "@coworker/locales";
import { PleaseFillIn } from "../Reusable/PleaseFillIn";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import Button from "@ingka/button";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";
import { Task } from "@coworker/types/lib/tasks/base";

type FormMode = "create" | "edit" | "complete";

interface FormProps {
  config: TaskFormTypes;
  task_id: string;
  formType: string;
  formMode: FormMode;
  baseTask: Task;
}

function Form({ config, task_id, formType, formMode, baseTask }: FormProps) {
  const { isOnline } = useOnline();
  const {
    closeForm,
    isFormValid,
    creating,
    createTaskAction,
    updateTaskAction,
    completeWithChangesAction,
    preparedForm,
    form,
    editFields,
    taskPath,
    task,
  } = useTaskForm({
    config,
    task_id,
    formType,
    formMode,
    baseTask,
  });
  const [buttonPressed, setButtonPressed] = React.useState(false);

  const canNotCreate = !isFormValid || !isOnline || creating;
  const getHeaderTitle = () => {
    if (formMode === "complete") {
      return typeof config.header.editTitle === "function"
        ? config.header.editTitle(preparedForm)
        : config.header.editTitle;
    } else if (formMode === "edit") {
      return config.header.editTitle;
    } else if (formMode === "create") {
      return config.header.newTitle;
    }
  };

  const { pop } = useWorkspacesAction();
  const getAppBarActionDefinitionByFormMode = (mode: FormMode) => {
    switch (mode) {
      case "create":
        return {
          title: getHeaderTitle(),
          backButton: true,
          onBack: closeForm,
          actions: [],
        };
      case "edit":
        return {
          title: getHeaderTitle(),
          backButton: false,
          actions: [
            {
              name: "Cancel",
              icon: <Trans>cancelString</Trans>,
              onClick: () => {
                pop();
              },
              position: "left" as const,
              testId: "CANCEL",
            },
            {
              name: "Save",
              icon: <Trans>saveString</Trans>,
              onClick: updateTaskAction,
              position: "right" as const,
              disabled: !isOnline || !isFormValid,
              testId: "SAVE",
            },
          ],
        };
      case "complete":
        return {
          title: getHeaderTitle(),
          actions: [],
        };
    }
  };

  return (
    <FullScreenPopup
      appBarConfig={getAppBarActionDefinitionByFormMode(formMode)}
      noPadding
      actionBarContent={
        <>
          {formMode === "create" && (
            <>
              <PleaseFillIn
                show={buttonPressed && canNotCreate}
                alternateWarning="fillInMandatoryFieldsString"
                centerText={true}
              />
              <Button
                type="primary"
                fluid
                text={
                  isOnline ? (
                    preparedForm.refilled_by_me ? (
                      <Trans>submitCompletedTaskString</Trans>
                    ) : (
                      <Trans>createString</Trans>
                    )
                  ) : (
                    <Trans>goOnlineString</Trans>
                  )
                }
                disabled={
                  formType === "mfaq_followup" ||
                  formType === "product_quality" ||
                  formType === "addon_create"
                    ? false
                    : canNotCreate
                }
                onClick={() => {
                  if (!canNotCreate) {
                    createTaskAction();
                    setButtonPressed(false);
                  } else {
                    setButtonPressed(true);
                  }
                }}
                data-testid="confirmButton"
                loading={creating}
              />
            </>
          )}
          {formMode === "complete" && (
            <Button
              type="primary"
              fluid
              text={
                isOnline ? (
                  preparedForm.pick_quantity === 0 ||
                  preparedForm.refilled_quantity === 0 ? (
                    <Trans>closeString</Trans>
                  ) : (
                    <Trans>completeString</Trans>
                  )
                ) : (
                  <>
                    <Trans>goOnlineString</Trans> {<div>one</div>}
                  </>
                )
              }
              disabled={!isFormValid || !isOnline}
              onClick={completeWithChangesAction}
              data-testid="confirmButton"
            />
          )}
        </>
      }
    >
      {config.fields.map((field, id) => {
        if (field.hide && field.hide(task_id, preparedForm)) {
          return null;
        }
        const Widget = widgets[field.type];

        return (
          <Widget
            key={`${field.type}-${id}`}
            value={(field.values ? field.values.in : (id: string) => id)(
              form,
              task
            )}
            onChange={(change: any) =>
              editFields(field.values ? field.values.out(change, task) : change)
            }
            taskPath={taskPath}
            conf={field.conf}
            taskId={task_id}
            formType={formType}
            last={id === config.fields.length - 1}
            disabled={
              field.disabled
                ? field.disabled({ isEdit: formMode === "edit" })
                : false
            }
            additionalSubTitle={<PleaseFillIn show={buttonPressed} />}
          />
        );
      })}
    </FullScreenPopup>
  );
}

export function generateTaskForm(type: TaskFormTypeNamesType, mode: FormMode) {
  return (props: Partial<FormProps>) => {
    const { id } = useParams();
    return (
      <Form
        formMode={mode}
        formType={type}
        // @ts-ignore
        config={taskTypes[type]}
        task_id={id || ""}
        {...props}
      />
    );
  };
}
